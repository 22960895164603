<template>
  <div :style="{
    padding: noPadding ? '0' : '1rem',
    borderLeft: noBorder ? 'none' : '1px solid #eceff3'
  }">
    <BaseText v-if="!hideTitle" type="label" size="sm">
      Selected KPI's
    </BaseText>
    <div v-if="includedColumns.length > 0" class="flex flex-col gap-1" :style="{
      marginTop: hideTitle ? '0' : '12px'
    }">
      <draggable v-model="includedColumns" tag="ul" handle=".drag-handle" v-bind="dragOptions" @start="drag = true"
        @end="drag = false" @change="updateTableColumnOrder">
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <!-- Selected list -->
          <div v-for="kpi in includedColumns" :key="kpi" class="flex items-center gap-1 justify-between py-1 px-0.5">
            <div class="flex gap-1 truncate">
              <DragListIcon class="text-icon-disabled drag-handle cursor-pointer flex-shrink-0" />
              <BaseText class="text-text-muted flex-shrink truncate" type="label" size="sm">
                <!-- To be changed -->
                {{ kpiLookup[kpi] || kpi }}
              </BaseText>
            </div>
            <TagRemoveIcon class="cursor-pointer flex-shrink-0" @click="removeKPI(kpi)" />
          </div>
        </transition-group>
      </draggable>
    </div>
    <div v-else class="w-ful mt-32 flex flex-col items-center justify-center gap-1 text-center">
      <BaseText class="text-text-normal" type="label" size="sm">
        No Selected KPI's
      </BaseText>
      <BaseText class="text-text-subdued w-64" size="sm">
        Add KPI's to your report by selecting them from the list on the left.
      </BaseText>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'
import { kpiLookup } from '../metrics'
// Icons
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import DragListIcon from '../../globals/Icons/DragListIcon.vue'

export default {
  name: 'SelectedKPISidebar',
  components: {
    TagRemoveIcon,
    DragListIcon,
    draggable
  },
  props: {
    hideTitle: {
      type: Boolean,
      default: () => false
    },
    noPadding: {
      type: Boolean,
      default: () => false
    },
    noBorder: {
      type: Boolean,
      default: () => false
    },
    tableColumns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const dragOptions = {
      animation: 150,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost'
    }

    return {
      kpiLookup,

      dragOptions,
      drag: false,
      includedColumns: []
    }
  },
  watch: {
    tableColumns(val) {
      this.includedColumns = val
    }
  },
  mounted() {
    this.includedColumns = this.tableColumns
  },
  methods: {
    ...mapActions('LensModule', ['updateLocalPresetField']),
    updateTableColumnOrder() {
      this.updateLocalPresetField({
        key: 'tableColumns',
        value: this.includedColumns
      })
      this.$emit('change')
    },
    removeKPI(kpiKey) {
      this.includedColumns = this.includedColumns.filter(kpi => kpi !== kpiKey)
      this.updateTableColumnOrder()
    }
  }

}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;

}

.ghost {
  opacity: 0.5;
  background-color: #F6F8FA;
}
</style>
