// Imports
import Axios from 'axios'

let AxiosConfig
if (process.env.DEV_NODE_ENV === 'QA') {
  // Temp way to expose dev server to QA branch
  AxiosConfig = Axios.create({
    baseURL: process.env.NGROK_EXPRESS_URL
  })
} else if (process.env.NODE_ENV === 'development') {
  // Axios Config
  AxiosConfig = Axios.create({
    baseURL: 'https://foreplay-dev-67500.ue.r.appspot.com'
    // baseURL: 'http://localhost:5050'
  })
} else {
  // Axios Config
  AxiosConfig = Axios.create({
    // baseURL: 'http://localhost:5050'
    baseURL: 'https://api.foreplay.co'
  })
}

export default AxiosConfig
